<template>
  <div>
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
      :labelWidth="labelWidth"
    >
      <el-button type="primary" @click="search">查询</el-button>
      <el-button type="primary" @click="reset">重置</el-button>
    </FormView>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getEmerDrillRecordsList"
    >
      <!-- @changeCurrent="changeCurrent" -->
      <template #action>
        <el-table-column label="操作" width="230" align="center">
          <template #default="scoped">
            <el-button type="primary" size="small" @click="aaa(scoped.row)">
              查看
            </el-button>
            <el-button type="primary" size="small"> 修改 </el-button>
            <el-button type="primary" size="small"> 删除 </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
const initData = reactive({
  loading: false,
  queryParams: {
    organizationDepartment: '',
    drillingShape: '',
    organizeUnit: ''
  },
  pagination: {
    current: 1,
    size: 10,
    total: 15
  },
  isPaginationShow: true,
  rTableData: [
    {
      drillingTheme: 'aa',
      drillingShape: 'bb'
    }
  ],
  columns: [
    {
      index: true,
      indexMethod(index) {
        return index + 1
      },
      width: 70
    },
    { selection: true },
    { prop: 'drillingTheme', label: '演练主题' },
    {
      prop: 'drillingShape',
      label: '演练形式'
      // formatter: this.handleDrillingShape
    },
    { slot: 'action' }
  ],
  formLabels: [
    //表单格式
    { title: '名字', label: 'name' },
    {
      title: '性别',
      label: 'sex',
      type: 'options',
      options: [
        { value: 1, label: '男' },
        { value: 2, label: '女' }
      ]
    },
    { title: '出生日期', label: 'birth', type: 'datePicker' },
    { title: '家庭住址地址', label: 'address' }
  ],
  fromData: {
    // 表单数据
    name: '',
    sex: '',
    birth: '',
    address: ''
  },
  formColumn: 4,
  labelWidth: '100px'
})
// 获取数据
const getEmerDrillRecordsList = (val) => {
  loading.value = true
  setTimeout(() => {
    loading.value = false
  }, 2000)
  pagination.value.size = val || 10
}

const aaa = (row) => {
  console.log(row)
}

// 单选
// const changeCurrent = () => {}

onMounted(() => {
  console.log(DateFormat(new Date(), ''))

  getEmerDrillRecordsList()
})

// 查询
const search = () => {
  console.log(fromData)
  getEmerDrillRecordsList()
}

const reset = () => {
  // 表单数据
  fromData.value = {
    name: '',
    sex: '',
    birth: '',
    address: ''
  }
  getEmerDrillRecordsList()
}

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth
} = toRefs(initData)
</script>

<style lang="scss" scoped></style>
